<template>
  <div>
    <b-modal v-model="openTransfer" footer-bg-variant="light" size="lg" @show='showboxfunc' @hide="hideboxfunc">
      <template #modal-title="{close}">
        <span class="font-up-4">轉移 - 旅遊行程</span>
      </template>

      <template #default>
        <b-tabs content-class="border border-top-0 m-0 p-2" v-model="tabShow">
          <b-tab :title="tab.name" v-for="(tab, index) in tabActive" :key="'tab__'+index">

            <template v-if="tabShow == 0">
              <div class="d-flex flex-column" v-if="tranProductUrl.length>0">
                <div class="d-flex" v-for="(product, index) in tranProductUrl"
                  :key="'product__'+index">
                  <span class="px-2">{{product.name}}</span>

                  <a class="flex-fill" :href="product.url" target="_blank">{{product.url}}->D</a>
                
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center"
                v-if="!(tranProductUrl.length>0)">
                <span class="text-danger">
                  尚未轉換成產品
                </span>
              </div>
            </template>

            <template v-if="tabShow == 1">
              <div v-if="(transferPage == 0)">
                <div class="d-flex flex-column">
                  <div class="form-group">
                    <label>出發日期</label>
                    <date-picker class="w-100" placeholder="請選擇出發日期" :first-day-of-week="1"
                      :clearable="true" :shortcuts="false" v-model="originalSchedule.StartDate" />
                  </div>

                  <div class="form-group">
                    <label>行程名稱</label>
                    <input type="type" class="form-control px-2" v-model="originalSchedule.Title">
                  </div>

                  <div class="form-group">
                    <label>圖片</label>
                    <input type="type" class="form-control px-2" v-model="schedule.image">
                  </div>

                  <div class="form-group">
                    <label>售價價格</label>
                    <input type="number" class="form-control px-2" min="0" v-model="schedule.price">
                  </div>

                  <div class="form-group">
                    <label>數量</label>
                    <input type="number" class="form-control px-2" min="0"
                      v-model="schedule.number">
                  </div>

                  <div class="form-group">
                    <label>簡介</label>
                    <textarea class="form-control p-2" v-model="schedule.webcontext"></textarea>
                  </div>

                  <div class="form-group">
                    <label>備註說明</label>
                    <textarea class="form-control p-2" v-model="schedule.remark"></textarea>
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex flex-column" v-if="(transferPage=='lastPage')">
                  <div class="d-flex flex-column" v-for="(day, key) in strokeFormat"
                    :key="'dayList__' + key">
                    <div class="d-flex align-items-center py-2">
                      <span class="px-2 text-nowrap">第 {{key+1}} 天</span>
                      <span class="px-2">{{day.name}}</span>
                    </div>
                    <div class="py-2 border">
                      <div class="d-flex align-items-center" v-for="(remark, index) in strokeRemark"
                        :key="'remark__' + index">
                        <div class="px-2">
                          <span>{{ (index==0) ? '餐點':'飯店' }}</span>
                        </div>
                        <div class="d-flex flex-fill">
                          <div class="d-flex flex-fill px-1"
                            v-for="(nodes, key) in remark[index].nodes" :key="'nodes__'+key">
                            <div class="flex-fill">
                              <label>{{ (index==0) ? '餐點':'飯店' }}名稱</label>
                              <input type="text" class="form-control px-2" v-model="nodes.name">
                              <label>{{ (index==0) ? '餐點':'飯店' }}網址</label>
                              <input type="text" class="form-control px-2" v-model="nodes.url">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-tab>

          <!-- <b-tab title="轉移">
            <template v-if="(transferPage == 0)">
              <div class="d-flex flex-column">
                <div class="form-group">
                  <label>出發日期</label>
                  <date-picker class="w-100" placeholder="請選擇出發日期" :first-day-of-week="1"
                    :clearable="true" :shortcuts="false" v-model="originalSchedule.StartDate" />
                </div>

                <div class="form-group">
                  <label>行程名稱</label>
                  <input type="type" class="form-control px-2" v-model="originalSchedule.Title">
                </div>

                <div class="form-group">
                  <label>圖片</label>
                  <input type="type" class="form-control px-2" v-model="schedule.image">
                </div>

                <div class="form-group">
                  <label>售價價格</label>
                  <input type="number" class="form-control px-2" min="0" v-model="schedule.price">
                </div>

                <div class="form-group">
                  <label>數量</label>
                  <input type="number" class="form-control px-2" min="0" v-model="schedule.number">
                </div>

                <div class="form-group">
                  <label>簡介</label>
                  <textarea class="form-control px-2" v-model="schedule.webcontext"></textarea>
                </div>

                <div class="form-group">
                  <label>備註說明</label>
                  <textarea class="form-control px-2" v-model="schedule.remark"></textarea>
                </div>
              </div>
            </template>
            <template>
              <div class="d-flex flex-column" v-if="(transferPage=='lastPage')">
                <div class="d-flex flex-column" v-for="(day, key) in strokeFormat"
                  :key="'dayList__' + key">
                  <div class="d-flex align-items-center">
                    <span class="px-2 text-nowrap">第 {{key+1}} 天</span>
                    <span class="px-2">{{day.name}}</span>
                  </div>
                  <div>
                    <div class="d-flex" v-for="(remark, index) in day.remark"
                      :key="'remark__' + index">
                      <span>{{remark.name}}</span>
                      <div class="d-flex flex-fill">
                        <div class="d-flex flex-fill" v-for="(nodes, index) in remark.nodes"
                          :key="'nodes__'+index">
                          <span class="flex-fill">{{nodes.name}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-tab> -->
        </b-tabs>
      </template>
      <template #modal-footer="{close}">
        <button class="btn btn-sm btn-primary" @click="transferPage='lastPage'"
          v-if="(transferPage!='lastPage') && (tabShow==1)">
          下一步
        </button>

        <button class="btn btn-sm btn-primary" @click="transferPage=0"
          v-if="(transferPage!=0) && (tabShow==1)">
          上一步
        </button>

        <button class="btn btn-sm btn-primary" @click="transferFunc()"
          v-if="(transferPage=='lastPage') && (tabShow==1)">
          轉移
        </button>

        <button class="btn btn-sm btn-danger" @click="close">
          關閉
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import ctrl from "./js/TransferData.js";
  export default ctrl;

</script>
