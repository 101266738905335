import store from "@/store";
import moment from "moment";
import _ from "lodash";
import "@/style/Edit.scss";
import {
  FormRadioPlugin
} from "bootstrap-vue";

export default {

  data() {
    console.log(process.env.VUE_APP_TRAVEL_URL)
    return {
      transferPage: 0,

      schedule: {
        image: "",
        context: "",
        remark: "",
        webcontext: "",
        number: 0,
        price: 0,
        remark: []
      },

      defaultRemark: [{
          field: "food",
          nodes: [{
            name: "敬請自理",
            url: ""
          }, {
            name: "敬請自理",
            url: ""
          }, {
            name: "敬請自理",
            url: ""
          }]
        },
        {
          field: "hotel",
          nodes: [{
            name: "溫暖的家",
            url: ""
          }]
        }
      ],

      tabShow: 0,

      tabActive: [{
        name: "轉移查詢"
      }, {
        name: "轉移產品"
      }]
    }
  },

  computed: {
    tranProductUrl:{
      get(){
        // return this.$store.state.travel.tranData.product;
        console.log("data")
        return this.$store.getters["travel/packageUrl"];
      },
    },

    openTransfer: {
      get() {
        return this.open;
      },
      set(data) {
        this.$emit("update:open", data);
      }
    },

    originalSchedule: {
      get() {
        return this.scheduleData;
      },
      set(data) {}
    },

    strokeRemark: {
      get() {
        let temp = _.map(this.$props.stroke, (item, key) => {
          return JSON.parse(JSON.stringify(this.defaultRemark));
        });
        return temp;
      }
    },

    strokeFormat() {

      let point = _.keyBy(this.pointData, "uid");
      let temp = _.map(this.stroke, (item, key) => {
        let title = [];
        let stroke = _.map(item.sub, (items, keys) => {
          title.push(items.title);
          let temp = point[items.place_id];

          return {
            name: temp.name,
            context: temp.info ? temp.info : temp.infoDetail ? temp.infoDetail : "",
            type: items.maintypes,
          }
        });
        title = title.join(" → ");
        return {
          name: title,
          stroke: stroke,
          remark: item.remark
        }
      });

      return temp
    }
  },

  methods: {
    showboxfunc(){
      console.log("showbox");
    },
    hideboxfunc(){
      console.log("hidebox");
    },
    transferFunc() {
      const data = this.schedule;
      data.name = this.originalSchedule.Title;
      data.TravelStart = this.originalSchedule.StartDate;
      data.stroke = this.stroke;
      data.strokeRemark = this.strokeRemark;

      this.$emit("func", data);
    }
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },

    list: {
      type: Array,
      default: []
    },

    scheduleData: Object,

    pointData: {
      type: Array,
      default: []
    },

    stroke: {
      type: Array,
      default: []
    }
  },

  watch: {},

  mounted() {
    this.$store.dispatch("travel/getTransferProduct", this.$route.params.Schedule);
  }
}
